export default {
  "page.admin.entry": "管理後台",

  "module.otp": "OTP",
  'module.order': '訂單管理',
  'module.orderAllProviders': '全供應商訂單管理',
  'module.dispatch': '訂單派遣',
  'module.delivery': '貨運列表',
  'module.backOrder': '退貨管理',
  'module.client': '客戶管理',
  "page.otp": "OTP",

  // data
  "otp.search_keyword": "輸入驗證碼、Email、手機號碼搜尋",
  "otp.data.type": "類型",
  "otp.data.feature": "用途",
  "otp.data.target": "Email或手機號碼",
  "otp.data.code": "驗證碼",
  "otp.data.ttl": "有效時間(秒)",
  "otp.data.allow_renew": "允許重新建立的時間長度(秒)",
  "otp.data.allow_renew_at": "允許重新建立的時間",
  "otp.data.expired_at": "到期時間",
  "ga_report.overview.title": "Google Analytics頁面瀏覽排行",
  "ga_report.overview.subtitle": "近30日內排行前10",
  "ga_report.data.pageview": "頁面瀏覽次數",
  "ga_report.data.users": "使用者造訪次數",
  "ga_report.users.title": "Google Analytics使用者造訪分析",
  "ga_report.users.subtitle": "近30日內使用者造訪次數",
  "data.empty": '查無結果',
  "data.main-manager": "主要管理員",
  "data.main-manager.true": "是",
  "data.main-manager.false": "",
  "data.main-manager.1": "是",
  "data.main-manager.0": "",
  'data.member_phone': "管理員電話",
  'data.email': 'Email',
  'data.city': '縣市',
  'data.district': '地區',
  'data.address': '地址',
  'data.ship-address': '出貨地址',
  'data.deliver-address': '送貨地址',
  'data-receive-address': '收貨地址',
  'data.phone': '電話',
  'data.uniform_number': '統一編號',
  'data.payment_type': '付款方式',
  'data.payment_type.cash_on_delivery': "貨到付款",
  'data.payment_type.cash_on_remit': "貨到匯款",
  'data.payment_type.pre_remit': "預先匯款",
  'data.payment_type.weekly': "周結",
  'data.payment_type.monthly': "月結",
  'data.payment_type.pay_first': "匯款後出貨",

  "photo.search_keyword": "輸入圖片名稱、原始檔名、UID搜尋",
  "photo.data.type": "用途標籤",
  "photo.data.name": "圖片名稱",
  "photo.data.extension": "副檔名",
  "photo.data.source_name": "原始檔名",

  "file.search_keyword": "輸入檔名搜尋",
  "file.data.type": "用途標籤",
  "file.data.file_type": "檔案類型",
  "file.data.filename": "檔名",
  "file.data.extension": "副檔名",

  // OTP用途
  "otp.data.feature.site-admin-reset-password": "管理員重設密碼",
  "otp.data.feature.member-reset-password": "會員重設密碼",
  "otp.data.feature.member-login-via-otp": "會員重設密碼",
  "otp.data.feature.member-register": "會員註冊",

  // action
  "action.send_verify.email": "發送驗證信件",
  "action.verify_code.login": "驗證碼登入",
  "action.verify.success": "驗證成功",
  "action.verify.error": "驗證失敗",
  "action.send_invite": "邀請共同管理員",
  "ga_report.action.force_update": "強制更新",
  "action.edit": "編輯",
  "action.down": "下架",
  "action.downed": "已下架",
  "action.up": "上架",
  'action.leave': '退群',
  'action.leave.ask': '是否退群?',
  'action.leave.success': '退群成功',
  'action.leave.fail': '退群失敗',
  'action.add.success': '加入成功',
  'action.add.fail': '加入失敗',

  'action.picking_list.export.pdf': 'PDF檔',
  'action.picking_list.export.excel': 'Excel檔',

  // others
  verify_code: "驗證碼",
  "otp.has_verify_code_already": "我已經有驗證碼了",
  "otp.has_verify_code_already.warning": "請先輸入Email",
  "otp.error.send": "驗證碼寄送失敗",
  "otp.error.valid": "驗證碼未過期",
  register_by_line: "Line註冊",
  "main-manager": "主要管理員",
  "co-manager": "共同管理員",
  "main_manager": "主要管理員",
  "co_manager": "共同管理員",
  "signer": "簽收人員",
  "picker": "揀貨人員",
  "dispatcher": "派單人員",
  "freightman": "貨運員",
  "only.main-manager.edit": "主要管理員才可進行修改",
  "cooperation.status": "合作狀態",
  'every-day-price':'每日浮動價格',

  // 供應商客戶
  'action.cooperation.accept': '接受合作',
  'action.cooperation.reject': '拒絕合作',
  'action.cooperation.reApproved': '重新核准',
  'action.cooperation.reRequest': '重新申請',
  'action.cooperation.cancel': '取消合作',
  'action.cooperation.accept.success': '接受合作成功',
  'action.cooperation.reject.success': '拒絕合作成功',
  'action.cooperation.reApproved.success': '重新核准成功',
  'action.cooperation.cancel.success': '已取消合作',
  'action.cooperation.accept.fail': '接受合作失敗',
  'action.cooperation.reject.fail': '拒絕合作失敗',
  'action.cooperation.reApproved.fail': '重新核准失敗',
  'action.cooperation.cancel.fail': '取消合作失敗，僅主要管理員可以進行此操作',


  // order
  'order.status.created': '新訂單',
  'order.status.apply': '未出貨',
  'order.status.sended': '出貨中',
  'order.status.finished': '已簽收',
  'order.status.canceled': '已取消',
  'order.status.reject': '已拒絕',
  'order.status.request_return': '退貨申請',
  'back-order.status': '退貨狀態',
  'back-order.status.APPLY': '退貨申請',
  'back-order.status.REJECT': '退貨失敗',
  'back-order.status.SUCCESS': '退貨成功',

  'store.order.status.created': '新訂單',
  'store.order.status.apply': '已接單',
  'store.order.status.sended': '出貨中',
  'store.order.status.finished': '已完成',
  'store.order.status.canceled': '取消',
  'store.order.status.reject': '已拒絕',
  'store.order.status.request_return': '退貨申請',

  'order.search_keyword': '輸入客戶名稱、訂單編號搜尋',
  'backOrder.search_keyword': '輸入客戶名稱、聯絡人、聯絡電話搜尋',
};
