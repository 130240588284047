<template>
  <v-system-bar
    v-if="status"
    height="30"
    :fixed="fixed"
    :color="color"
    :dark="dark"
    class="align-center justify-center text-center"
  >
    <span v-if="title">{{ title }}</span>
    <v-spacer></v-spacer>
    <div>
      <span v-if="content" class="mr-8">{{ content }}</span>
      <v-btn
        v-if="buttonCallback"
        @click="buttonCallback"
        class="ma-0"
        color="grey lighten--2"
        x-small
      >
        {{buttonText}}
      </v-btn>
    </div>
    <v-spacer></v-spacer>

    <div
      v-if="closeable"
      @click="close"
      class="cursor-pointer headline"
    >
      &times;
    </div>
  </v-system-bar>
</template>

<script lang="babel" type="text/babel">
import { events } from './events'
export default {
  data: () => ({
    status: false,
    options: {},
    listener: null,
    closeTimeout: null,
  }),
  created() {
    this.setupListener()
  },
  methods: {
    clearTimeout() {
      window.clearTimeout(this.closeTimeout)
      this.closeTimeout = null
    },
    setupListener() {
      this.listener = events
      this.listener.$on('active', this.active)
    },
    active(options) {
      this.options = options
      this.status = true
      if(this.timeout) {
        this.clearTimeout()
        this.closeTimeout = window.setTimeout(() => {
          this.close()
        }, this.timeout)
      }
    },
    onClose() {
      this.options = {}
    },
    close() {
      this.status = false
      this.onClose()
    },
  },
  computed: {
    fixed() {
      return this.options.fixed != false
    },
    color() {
      return this.options.color || 'primary'
    },
    content() {
      return this.options.content
    },
    title() {
      return this.options.title
    },
    dark() {
      if(this.options.dark === undefined) return true
      return this.options.dark
    },
    buttonCallback() {
      if(typeof this.options.buttonCallback != 'function') return null
      return this.options.buttonCallback
    },
    buttonText() {
      return this.$t('action')
    },
    timeout() {
      const timeout = parseInt(this.options.timeout)
      if(isNaN(timeout) === false) return timeout
      return null
    },
    closeable() {
      if(this.options.closeable === undefined) return true
      return this.options.closeable
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>