import Vue from 'vue'

// @ts-ignore
import validator from 'validator'
class validator {
  protected helper: any
  protected vm: Vue

  constructor(helper: any, vm: Vue) {
    this.helper = helper
    this.vm = vm
  }


  email(data: string) : boolean {
    if(typeof data != 'string') return false
    if(this.vm.$validator.isEmail(data) === false) return false
    return true
  }

  emailRule(data: any) : boolean|string{
    return this.email(data) || this.vm.$t('validate.error.email_incorrect')
  }

  minText(data: any, length: number) {
    if(typeof data != 'string') return false
    return this.vm.$validator.isLength(data, { min: length })
  }

  minTextRule(data: any, length: number) : boolean|string{
    return this.minText(data, length) || this.vm.$t('validate.error.text_too_less', { length })
  }

  required(data: any) {
    return !window.eagleLodash.isEmpty(window.eagleLodash.trim(data))
  }

  requiredRule(data: any) : boolean|string{
    return this.required(data) || this.vm.$t('validate.error.required')
  }

  passwordValidate(password: any) {
    if(!this.required(password)) return false
    if(!this.minTextRule(password, 4)) return false
    return true
  }

  phoneNumberGlobal(string: string|null) : boolean {
    if(!string) return false
    const patten = /^((\+)\d{3})[1-9](\d{2}){4}$/
    return new RegExp(patten).test(string)
  }

  phoneNumberLocal(string: string|null) : boolean {
    if(!string) return false
    const patten = /^(09)[0-9]{8}$/
    return new RegExp(patten).test(string)
  }

  phoneNumberGlobalRule(data: any) : boolean|string{
    return this.phoneNumberGlobal(data) || this.vm.$t('validate.error.phone_number_incorrect')
  }

  phoneNumberLocalRule(data: any) : boolean|string{
    return this.phoneNumberLocal(data) || this.vm.$t('validate.error.phone_number_incorrect')
  }

  noWhitespace(value: any) : boolean {
    if(typeof value != 'string') return true
    return /\s/g.test(value) == false
  }

  noWhitespaceRule(data: any) : boolean|string{
    return this.noWhitespace(data) || this.vm.$t('validate.error.required')
  }

  /**
   * 字元檢查(黑名單字元), return true代表檢查通過
   * @param value
   * @param blacklist 黑名單字元檢查
   */
  charValid(value: any, blacklist: string[]) : boolean {
    if(typeof value != 'string') return true
    for(const invalidChar of blacklist) {
      if(new RegExp(invalidChar, 'g').test(value) === true) return false
    }
    return true
  }

  /**
   * 嚴格字串檢查
   * - 不可有空白
   * - 不可有特殊符號: ^ ' ` ! @ # % & * + \ " < >|_ [ ] 。
   * @param value
   */
  strictStringValid(value: any) : boolean {
    if(typeof value != 'string') return true
    const charBlackList = ['\\^', '\'', '`', '!', '@', '#', '\\%', '&', '\\*', '\\+', '"', '<', '>', '\\|', '_', '\\[', '\\]', '。']
    if(!this.charValid(value, charBlackList)) return false
    return true
  }

  onlyNumber(value: any) : boolean {
    if(!value) return false
    return new RegExp(/^\d+$/).test(value)
  }
}

export default (helper: any, vm: Vue) => new validator(helper, vm)
export interface ValidatorInterface {
  email: (data: string) => boolean,
  emailRule: (data: string) => boolean|string,
  minText: (data: any, length: number) => boolean,
  minTextRule: (data: any, length: number) => boolean|string,
  required: (data: string) => boolean,
  requiredRule: (data: string) => boolean|string,
  phoneNumberLocal: (string: string|null) => boolean,
  phoneNumberLocalRule: (data: any) => boolean|string,
  phoneNumberGlobal: (string: string|null) => boolean,
  phoneNumberGlobalRule: (data: any) => boolean|string,
  noWhitespace(value: any) : boolean,
  charValid(value: any, blacklist: string[]) : boolean,
  strictStringValid(value: any) : boolean
  onlyNumber(value: any) : boolean,
}
