<template>
    <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right @click="toTop" class="flex-column-btn"
        :class="btnClass">
        <div class="btn-text-xs font-weight-light">TOP</div>
        <v-icon>fa fa-arrow-up</v-icon>
    </v-btn>
</template>

<script>
export default {
    data: () => ({
        fab: false,
        lastScrollTop: 0,
        hideTimeout: null,
    }),
    computed: {
        websitType() {
            return this.$store.getters[`token/tokenRole`]
        },
        btnClass() {
            return this.websitType + '-bg-color'
        },
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll(e) {
            if (typeof window === 'undefined') return;

            const top = window.pageYOffset || e.target.scrollTop || 0;

            if (top > this.lastScrollTop) {
                this.fab = top > 20;

                if (this.hideTimeout) clearTimeout(this.hideTimeout);

                this.hideTimeout = setTimeout(() => {
                    this.fab = false;
                }, 1500);
            } else {
                this.fab = false;

                if (this.hideTimeout) clearTimeout(this.hideTimeout);
            }

            this.lastScrollTop = top;
        },
        toTop() {
            this.$vuetify.goTo(0);
        }
    },
}
</script>

<style lang="sass">
.flex-column-btn
    .v-btn__content
        flex-direction: column

    .btn-text-xs
        font-size: 12px
</style>
