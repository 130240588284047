import Vue from 'vue'
import {ActionContext} from 'vuex/types'
import {TokenStoreInterface} from 'kernel/libs/tokenStore'

import tokenStore from 'kernel/libs/tokenStore'
window.tokenStore = tokenStore
Vue.prototype.$tokenStore = tokenStore

interface State {
  tokenStore: TokenStoreInterface,
}

export default {
  namespaced: true,
  state: {
    tokenStore: window.tokenStore
  },
  mutations: {
    set(state: State, token: string) {
      state.tokenStore.set(token)
    },
    clean(state: State) {
      state.tokenStore.clean()
    },
  },
  actions: {
    set(context: ActionContext<any, any>, token: string) {
      context.commit('set', token)
    },
    clean(context: ActionContext<any, any>) {
      context.commit('clean')
    },
  },
  getters: {
    tokenStore: (state: State) : TokenStoreInterface => state.tokenStore,
    isLogin: (state: State) : boolean => state.tokenStore.isLogin,
    type: (state: State) : string|null => state.tokenStore.type,
    roles: (state: State) : string[] => {
      if(!state.tokenStore.data) return []
      if(!Array.isArray(state.tokenStore.data.roles)) return []
      return state.tokenStore.data.roles
    },
    hasRole: (state: State) : (requiredRoles: string[]) => boolean => {
      return state.tokenStore.hasRole
    },
    data: (state: State, getters: any) : any => {
      return state.tokenStore.data
    },
    expiredAt: (state: State) => state.tokenStore.expiredAt,
    isAdmin: (state: State, getters: any) => {
      return getters.type === 'admin'
    },
    name: (state: State, getters: any) => {
      return getters.tokenStore.name
    },
    avatar: (state: State, getters: any) => {
      const avatar = window.eagleLodash.get(state.tokenStore.data, ['avatar'])
      if(!avatar) return require('assets/account.png')
      return avatar
    },
    email: (state: State, getters: any) => {
      if(!getters.data) return null
      return getters.data.email
    },
    memberId: (state: State, getters: any) => {
      if(!getters.data) return null
      return getters.data.id
    },
    // providerId: (state: State, getters: any) => {
    //   if(!getters.data) return null
    //   if(Array.isArray(getters.data.provider_id)) {
    //     // 先暫定取第一個
    //     return getters.data.provider_id[0]
    //   }
    //   return getters.data.provider_id
    // },
    // provider: (state: State, getters: any) => {
    //   if(!getters.data) return null
    //   return getters.data.provider
    // },
    // providerName: (state: State, getters: any) => {
    //   if(!getters.data) return null
    //   return window.eagleLodash.get(getters.data, 'provider.name') || null
    // },
    // isMaintainer: (state: State) => {
    //   if(!state.tokenStore.isLogin) return false
    //   if(state.tokenStore.type != 'admin') return false
    //   return state.tokenStore.hasRole(['ROLE_MAINTAINER'])
    // },
    
    // provider or store
    tokenRole: (state: State, getters: any) => {
      return window.eagleLodash.get(getters.data, 'role') || null
    },
  },
}
