import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import {ModuleSelectorConfigType} from 'kernel/libs/moduleSelector'
import baseConstants, {TokenRoleConstants} from '@/modules/base/config/baseConstants'

declare module 'vue/types/vue' {
  interface Vue {
    $tokenRoleConstants: TokenRoleConstants,
  }
}

class moduleIndex {
  private vm: Vue|undefined
  public name: string
  constructor() {
    this.name = 'base'
  }

  // 模組啟動, 當有使用的時候會帶入vm
  public async launch(vm: Vue) : Promise<void> {
    Vue.prototype.$tokenRoleConstant = baseConstants.TokenRoleConstant
    this.vm = vm
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/base/config/menu'),
      realApi: () => import('modules/base/config/realApi'),
      routes: () => import('modules/base/config/route'),
    }
  }
}

export default new moduleIndex()
