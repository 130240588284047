import { ActionContext } from 'vuex/types';

interface State {
  publicMenuId: string;
}

export default {
  namespaced: true,
  state: <State>{
    publicMenuId: '',
  },
  mutations: {
    setPublicMenuId: (state: State, id: string) => {
      state.publicMenuId = id;
    },
  },
  actions: {
    setPublicMenuId: (context: ActionContext<string, boolean>, id: string) => {
      context.commit('setPublicMenuId', id);
    },
  },
  getters: {
    publicMenuId: (state: State): string => state.publicMenuId,
  },
};
