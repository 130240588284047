<template>
    <v-btn v-if="showBackButton && websitType" depressed fixed bottom left @click="goBack" class="flex-column-btn back-btn" :class="btnClass">
        <div class="btn-text-xs font-weight-light">BACK</div>
        <v-icon class="text-xl">fa fa-arrow-left</v-icon>
    </v-btn>
</template>

<script>
export default {
    computed: {
        showBackButton() {
            return false
            // return this.$route.path !== '/' && this.$route.name !== 'provider-orders';
        },
        websitType() {
            return this.$store.getters[`token/tokenRole`]
        },
        btnClass() {
            return this.websitType + '-font-color'
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
    },
    mounted() {
        
    },
}
</script>
<style lang="sass">
.back-btn
    height: initial!important
    padding: 0.5rem 0!important
    border: 1px solid #00000063
    background-color: #F5F5F5
    border-radius: 10px
    min-width: 59px!important
</style>