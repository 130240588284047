import { ActionContext } from 'vuex/types'

interface State {
  cart: AnyObject | null,
  show: boolean
}

export default {
  namespaced: true,
  state: <State>{
    cart: null,
    show: false,
  },
  mutations: {
    setCart(state: State, data: AnyObject[]) {
      state.cart = data
    },
    setShowCart(state: State, data: boolean) {
      state.show = data
    },
  },
  actions: {
    setCart(context: ActionContext<string, boolean>, data: AnyObject[]) {
      context.commit('setCart', data)
    },
    setShowCart(context: ActionContext<string, boolean>, data: boolean) {
      context.commit('setShowCart', data)
    },
  },
  getters: {
    show: (state: any, getters: any) => { return state.show },
    cart: (state: any, getters: any) => { return state.cart },
    totalQuantity: (state: any, getters: any) => {
      if (!getters.cart) return 0
      return getters.cart.length

      // return getters.cart.reduce((acc: any, cur: any) => {
      //   acc += cur.quantity
      //   return acc
      // }, 0)
    },
  },
}
