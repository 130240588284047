// @ts-ignore
import storage from 'libs/storage/localStorage.js'
import dayjs from 'dayjs'
import { EagleApiInterface } from 'kernel/api/api'
interface TokenData {
  id: string,
  type: string,
  expired_at: string,
}

class tokenStore {
  // eslint-disable-next-line
  token: string|null
  type: string|null
  payload: {[key: string]: any}|null
  name: string|null
  data: {[key: string]: any}|null
  expiredAt?: string|null
  storage: any
  isLogin: boolean
  debugMode: boolean
  timeout: ReturnType<typeof setTimeout> | null
  isTabFocus: boolean
  renewLoading: boolean
  readonly storageKey: string
  tokenData: TokenData | null
  api: EagleApiInterface | null

  constructor() {
    this.isLogin = false
    this.debugMode = false
    this.token = null
    this.name = null
    this.data = null
    this.type = null
    this.payload = null
    this.expiredAt = null
    this.timeout = null
    this.renewLoading = false
    this.isTabFocus = false
    this.storageKey = 'token'
    this.tokenData = null
    this.api = null
    this._init()
  }

  async _init() {
    this.storage = storage()

    if(this.storage.get('tokenDebug')) {
      this.debugMode = true
    }

    this._refreshData()

    // token過期或格式錯誤就清除
    if(!this._isValid()) {
      this.clean()
      return
    }

    this._countdown()
  }

  setupApi(api: EagleApiInterface) {
    this.api = api
  }

  // 是否有效
  _isValid() : boolean{
    if(!this.get()) return false
    if(!this.parsePayload()) return false
    const exp = this.parsePayload().exp
    if(!exp) return false
    const now = this.currentTimestamp()
    return exp > now
  }

  currentTimestamp() {
    return Math.floor(new Date().getTime()/1000)
  }

  getRoles() : string[] {
    const roles = this.getData('roles')
    if(!Array.isArray(roles)) return []
    return this._handleRoles(roles)
  }

  hasRole(requiredRoles: string[]) : boolean {
    const tokenRoles = this.getRoles()

    // 沒設定
    if(!Array.isArray(requiredRoles)) return true

    // 權限是工程帳號
    if(tokenRoles.indexOf('ROLE_MAINTAINER') > -1) return true

    for(const role of tokenRoles) {
      // token內的權限有一個是符合權限的設定
      if(requiredRoles.indexOf(role) > -1) return true
    }

    return false
  }

  clean(cleanEnduringToken: boolean = false) {
    if(!this.get()) {
      this._refreshData()
      return
    }
    this.storage.delete(this.storageKey)
    this._refreshData()
    if(window.crosstab) window.crosstab.broadcast('cleanToken', null, null)
  }

  get() : string {
    return this.storage.get(this.storageKey) || ''
  }

  set(token: string | null) : void {
    if(window.crosstab && this.get() != token) {
      window.crosstab.broadcast('setToken', {
        token,
      }, null)
    }

    this.storage.set(this.storageKey, token)
    this._refreshData()
    this._countdown()
  }

  _refreshData() {
    this.token = this.get()
    this.data = this.getData()
    this.name = window.eagleLodash.get(this.data, ['name']) || window.eagleLodash.get(this.data, ['email'])
    this.type = this.getType()
    this.payload = this.parsePayload()
    this.expiredAt = this._getExpiredAt()
    this.isLogin = this._isValid()
  }

  getType() {
    const payload = this.parsePayload()
    if(!payload) return null
    return payload.type
  }

  getData(property: string|null = null) {
    const payload = this.parsePayload()
    if(!payload) return null
    if(payload.data.roles) {
      payload.data.roles = this._handleRoles(payload.data.roles)
    }
    if(!property) return payload.data
    if(!payload.data) return null
    return window.eagleLodash.get(payload.data, property)
  }

  // eslint-disable-next-line
  _handleRoles(originalRoles: any) {
    const roles = window.eagleLodash.cloneDeep(originalRoles)
    if(!roles) return roles
    const arrayRole = []
    if(typeof roles === 'object') {
      for(const key in roles) {
        arrayRole.push(roles[key])
      }
    }
    return window.eagleLodash.uniq(arrayRole)
  }

  _getExpiredAt() {
    if(!this.tokenData) return null
    if(!this.tokenData.expired_at) return null
    return dayjs(this.tokenData.expired_at).format('YYYY-MM-DD HH:mm:ss')
  }

  _countdown() : void {
    if(!this.get()) return
    const surplusTime = this._getTokenSurplusTime()

    // 剩餘N秒執行renew(N秒介於5-10分鐘), 用random避免多tab同時renew
    // const renewAt = 3595 // debug可使用每5秒更新一次token確認
    const renewAt = window.eagleLodash.random(5*60, 10*60)
    let timeoutSecond = surplusTime - renewAt
    if(timeoutSecond > 86400*10) timeoutSecond = 86400*10 // 避免setTimeout時間太大失效變成立即執行
    this.debugLog(`${surplusTime}秒後到期, ${timeoutSecond}秒後將自動更新`)

    // 剩餘時間>5分鐘
    if(surplusTime > renewAt) {
      this.timeout = setTimeout(() => {
        this.renew()
      }, timeoutSecond * 1000)
      return
    }

    // 剩餘時間在5分鐘內
    if (surplusTime <= renewAt && surplusTime > 0) {
      this.renew()
      return
    }

    // token過期直接清除
    this.clean(true)
  }

  delay(second = 1) : Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, second * 1000)
    })
  }

  // 更新token
  async renew() {
    await this.delay(2)
    // api尚未初始化完成, 等一秒後再call一次renew method
    if(!this.api) {
      window.setTimeout(() => {
        this.renew()
      }, 2000)
      return
    }

    try {
      const result = await this.api.collection.siteAdminAccountApi.renewToken()
      this.set(result.token)
    } catch (error) {
      console.error(error)
    }
  }

  // 取得剩餘時間(單位: 秒)
  _getTokenSurplusTime() : number {
    if(!this.get()) return -1
    const payload = this.parsePayload()
    if(!payload) return -1
    return payload.exp - this.currentTimestamp()
  }

  parsePayload() {
    if(!this.get()) return null
    try {
      const jwtPayload = (this.get().split('.')[1]).replace(/-/g, '+').replace(/_/g, '/')
      return JSON.parse(atob(jwtPayload))
    } catch(error) {
      console.warn(error)
      return null
    }
  }

  debugLog(...args: any[]) : void{
    if(!this.debugMode) return
    console.warn('[Token Debug]', ...args)
  }
}

export default new tokenStore()

export interface HasRoleInterface {
  (requiredRoles: string[]) : boolean
}

export interface TokenStoreInterface {
  isLogin: boolean
  token: string|null
  type: string|null
  name: string|null
  payload: {[key: string]: any}|null
  data: {[key: string]: any}|null
  expiredAt?: string|null
  setupApi: (api: EagleApiInterface) => void
  hasRole: (requiredRoles: string[]) => boolean
  clean: () => void
  get: () => string
  set: (token: string|null) => void
}

export declare type TokenType = 'admin'|'member'
