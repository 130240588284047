import Vue from 'vue'

import {
  cloneDeep, isEqual, sortBy, orderBy, upperFirst, isEmpty, trim, snakeCase,
  random, uniq, uniqBy, has, get ,set , includes, slice, find, remove, merge,
  replace, join, lowerCase, round, differenceWith, sumBy
} from 'lodash'

import {EagleLodashType} from 'shims-vue'

const eagleLodashLibrary = <EagleLodashType>{
  cloneDeep,
  isEqual,
  sortBy,
  orderBy,
  upperFirst,
  isEmpty,
  trim,
  snakeCase,
  random,
  uniq,
  uniqBy,
  has,
  get,
  set,
  includes,
  slice,
  round,
  find,
  remove,
  merge,
  join,
  replace,
  lowerCase,
  differenceWith,
  sumBy,
}

// @ts-ignore
window.eagleLodash = <EagleLodashType>eagleLodashLibrary
Vue.prototype.$eagleLodash = <EagleLodashType>eagleLodashLibrary
