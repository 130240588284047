import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import base from '@/store/modules/base'
import loading from '@/store/modules/loading'
import token from '@/store/modules/token'
import list from '@/store/modules/list'
import form from '@/store/modules/form'
import tree from '@/store/modules/tree'
import photo from '@/store/modules/photo'
import member from '@/store/modules/member'
import store from '@/store/modules/store'
import cart from '@/store/modules/cart'
import customForm from '@/store/modules/customForm'
import publicProvider from '@/store/modules/publicProvider'
export default new Vuex.Store({
  modules: {
    base,
    loading,
    token,
    list,
    form,
    tree,
    photo,
    member,
    store,
    cart,
    customForm,
    publicProvider
  }
})
