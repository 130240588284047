export default {
  "page.product": "商品",
  "module.product": "商品",
  'module.product_menu': '菜單',
  "module.product_unit": "商品單位",

  // data
  "product-menu.data.product": "商品",
  "product-menu.data.product_quantity": "商品數量",
  "product.data.description": "商品敘述",
  "product.data.shipping_unit": "出貨單位(購買的數量單位)",
  "product.data.calculate_unit": "計價單位(可與出貨單位相同)",
  "product.data.per_shipping_count": "數量/每出貨單位",
  "product.data.price": "單價(元/計價單位)",
  "product.data.daily_alert": "每日提醒變更價格",

  // action
  "action.create.product": "新增商品",
  "action.create.product_unit": "新增單位",

  // form
  'product.form_block.discount_config': '優惠設定',
  'product-unit.form.title.create': '新增單位',
  'product-unit.form.title.update': '編輯單位',
  'product-unit.data.name.placeholder': '請輸入單位名稱',

  // others
  "product.search_keyword": "輸入商品名稱搜尋",
  "product-menu.search_keyword": "輸入類別名稱搜尋",
};
