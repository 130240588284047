declare const window: any
// @ts-ignore
import $ from 'jquery'
// @ts-ignore
window.$ = $
// @ts-ignore
window.self.$ = $
import {liffClientId} from 'config/env.json'

import liff from '@line/liff'

// @ts-ignore
import oauth from 'kernel/libs/oauth'
// @ts-ignore
Vue.prototype.$oauth = oauth

// @ts-ignore
window.liff = liff
// alert(`liff版本: ${liff.getVersion()}`)
// @ts-ignore
// window.liff.init({
//   liffId: liffClientId,
// })

import 'kernel/libs/nl2br'
import helper from 'kernel/helper/helper'
window.helper = helper
Vue.prototype.$helper = helper

import vueRouteChangePrevent from 'kernel/libs/vueRouteChangePrevent'
window.vueRouteChangePrevent = vueRouteChangePrevent

import crossTab from 'kernel/libs/crossTab'
window.crosstab = crossTab

import zIndexConfig from 'config/zIndex'
window.$zIndexConfig = zIndexConfig
Vue.prototype.$zIndexConfig = zIndexConfig

// css一律引用在這, 如果寫在app.vue會導致打包後css引入順序跟開發時不一致而跑版
import "viewerjs/dist/viewer.css"
import "@/assets/sass/index.sass"

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import vuetify from './plugins/vuetify'
import language from 'kernel/libs/language'
language.init()
import bootstrap from 'modules/bootstrap'

import i18n from 'i18n'
Vue.config.productionTip = false
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

// @ts-ignore
import storage from 'libs/storage/localStorage.js'
Vue.prototype.$storage = storage()

class website {
  async start() {
    window.bootstrap = bootstrap
    await window.bootstrap.init()
    await this.startVue()
  }

  async startVue() {
    const router = (await import('./router')).default
    window.rootVm = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
      computed: {
        routeProviderId() {
          return this.$route.params.providerId;
        },
      },
      async created() {
        window.crosstab.on('setToken', (event: AnyObject, tabId: any) => {
          if (tabId == event.origin) return
          this.$tokenStore.set(event.data.token)
        })

        window.crosstab.on('cleanToken', (event: AnyObject, tabId: any) => {
          if (tabId == event.origin) return
          this.$tokenStore.clean()
        })
        // @ts-ignore
        this.setupI18n()
        // @ts-ignore
        this.setPriceFilter()
        // @ts-ignore
        this.setGAScript()
      },
      methods: {
        setupI18n() {
          this.$i18n.locale = language.get()
          this.$vuetify.lang.current = language.get()
          // @ts-ignore
          this.setupModuleI18n()

          this.$vuetify.lang.t = (key, ...params) => this.$t(key, params)

          // @ts-ignore
          Vue.filter('t', (...args) => this.$t(...args))
          // @ts-ignore
          Vue.filter('tc', (...args) => this.$tc(...args))
          // @ts-ignore
          Vue.filter('te', (...args) => this.$te(...args))
        },
        // 合併所有模組的i18n目錄語系
        setupModuleI18n() {
          const moduleLocales = require.context('modules', true, /i18n\/[A-Za-z0-9-_,\s]+\.js$/i)
          moduleLocales.keys().forEach(key => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i)
            if (matched && matched.length > 1) {
              const locale = matched[1]
              const moduleI18n = moduleLocales(key).default
              this.$i18n.mergeLocaleMessage(locale, moduleI18n)
            }
          })
        },
        setPriceFilter() {
          const func = (price: number | string, fixed= 2) => {
            const res =  (+price).toFixed(fixed)
            return +res
          }
          Vue.filter('fixedPrice', func)

          Vue.prototype.$fixedPrice = func
        },
        setGAScript(){
          // 測試機：G-L751RBXBTN 正式機：G-LLLM3P0FQM
          const ga4Script = () => {
            return `
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-LLLM3P0FQM"></script>
              <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'G-LLLM3P0FQM');
              </script>`
          }
          $('head').append(ga4Script())
        },
        gaLogEvent(eventName: string, info?: any){
          if(window.gtag){
            console.log('gaLogEvent:', eventName, info)
            window.gtag('event', eventName, info)
          }
        }
      },
    }).$mount('#app')
  }
}

(new website()).start()
