import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import {ModuleSelectorConfigType} from 'kernel/libs/moduleSelector'

class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'product'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{

    Vue.prototype.$productSelector = (config: ModuleSelectorConfigType) => {
      vm.$moduleSelector.active(vm, {
        ...config,
        title: vm.$t('module.product'),
        bodySlot: () => import('@/modules/product/selector/productList.vue'),
      })
    }
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/product/config/menu'),
      realApi: () => import('modules/product/config/realApi'),
      routes: () => import('modules/product/config/route'),
    }
  }
}

export default new moduleIndex()
