import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'

class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'member'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{

  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/member/config/menu'),
      realApi: () => import('modules/member/config/realApi'),
      routes: () => import('modules/member/config/route'),
    }
  }
}

export default new moduleIndex()
