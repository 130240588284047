import { ActionContext } from 'vuex/types'

interface State {
  store: AnyObject | null,
}

// 預設欄位, 如果後端讀取得格式有調整也要一起變動
const defaultStore: AnyObject = {
  name: null,
  member_name: null,
  phone: null,
  uniform_number: null,
  city: null,
  district: null,
  address: null,
  pivot: {
    comment: null,
    payment_type: null,
    status: null
  },
}

export default {
  namespaced: true,
  state: <State>{
    store: window.eagleLodash.cloneDeep(defaultStore),
  },
  mutations: {
    setStore(state: State, data: AnyObject[]) {
      state.store = data
    },
    setDefault(state: State) {
      state.store = window.eagleLodash.cloneDeep(defaultStore)
    },
  },
  actions: {
    async setStoreByApi(context: ActionContext<string, boolean>, data: any) {
      const { vm, storeId, providerId } = data
      const { dispatch, commit } = context
      if (!storeId || !providerId) {
        commit(`setDefault`)
        return
      }
      dispatch('loading/active', '', { root: true })
      try {
        const res = await vm.$api.collection.storeApi.readByProviderAndStore(storeId, providerId)
        commit(`setStore`, res)
      } catch (err) {
        commit(`setDefault`)
      } finally {
        dispatch('loading/close', '', { root: true })
      }
    },
    setStore(context: ActionContext<string, boolean>, data: AnyObject[]) {
      context.commit('setStore', data)
    },
    setDefault(context: ActionContext<string, boolean>) {
      context.commit('setDefault')
    },
  },
  getters: {
    store: (state: any, getters: any) => { return state.store },
    cooperationStatus: (state: any, getters: any) => {
      if (!state.store) return null
      if (!state.store.pivot) return null
      return state.store.pivot.status
    },
    isCooperation: (state: any, getters: any) => {
      if(!getters.cooperationStatus) return false
      return getters.cooperationStatus == 'cooperation'
    },
  },
}
