export default {
  'page.member': 'member',
  'module.member': '會員',

  // data
  'member.data.name': "會員名稱",
  "member.data.email": "會員email",

  // form
  // 'member.form_block.foobar': 'foobar',

  // others
  'provider-member.search_keyword': '輸入管理員名稱搜尋',
}
